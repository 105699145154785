<template>
  <div class="wrap">
    <ul class="address-book">
      <li
        key="new"
        class="list-x-item new-addr"
        @click="create()"
      >
        + {{ $t("new_address") }}
      </li>
      <li
        v-for="(a, aindex) in list"
        :key="a.id"
        class="list-x-item"
      >
        <div class="more">
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
          >
            <div slot="trigger">
              <b-icon
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
            </div>

            <b-dropdown-item
              aria-role="listitem"
              @click="update(a, aindex)"
            >
              <b-icon
                icon="pencil"
                size="is-small"
              />
              {{ $t("edit") }}
            </b-dropdown-item>

            <b-dropdown-item
              v-show="selected_address !== a.oid"
              aria-role="listitem"
              @click="remove(a)"
            >
              <b-icon
                icon="delete"
                type="is-danger"
                size="is-small"
              />
              {{ $t("delete") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          v-bind:class="[
            action_to === a.id ? 'active' : null,
            updated_address === a.id ? 'updated' : null,
            deleted_address === a.id ? 'deleted' : null
          ]"
          name="slide-y-transition"
          mode="out-in"
          class="addr-container columns is-mobile is-multiline m-0"
          @click="action_to === a.id || setAddresses(a)"
        >
          <div class="column is-2-mobile is-1-tablet">
            <b-radio
              v-model="selected_address"
              :native-value="a.id"
              class="mt-1"
            />
          </div>

          <ul class="column is-10-mobible is-5-tablet contact">
            <li class="name">
              {{ a.fname }} {{ a.lname }}
            </li>
            <li v-if="a.phone != null">
              {{ a.phone.code }} {{ a.phone.number }}
            </li>
          </ul>
          <ul class="column is-12-mobile is-6-tablet address">
            <li v-if="a.company">
              {{ a.company }}
            </li>
            <li>{{ a.address1 }}</li>
            <li v-if="a.address2">
              {{ a.address2 }}
            </li>
            <li>
              {{ a.city }}, {{ a.zip }} -
              <span v-if="a.country">{{ a.country.country }}</span>
            </li>
          </ul>
        </div>
        <b-loading
          v-if="!carriers_loaded && action_to === a.id"
          :is-full-page="false"
          :active="true"
          type="is-primary"
        />
        <ul
          v-if="action_to === a.id && carriers.length > 0 && carriers_loaded"
          class="carriers"
        >
          <li
            v-for="(carrier, cindex) in carriers"
            :key="carrier.id"
            class="columns is-mobile is-multiline m-0"
            v-bind:class="[cindex === selected_carrier ? 'active' : null]"
            @click="cindex === selected_carrier || updateCarrier(carrier)"
          >
            <div class="column is-1-tablet is-2-mobile">
              <b-radio
                v-model="selected_carrier"
                :native-value="carrier.id"
                class="mt-1"
              />
            </div>

            <div class="column is-10-mobile is-4 tablet logo">
              <b-tooltip
                :label="carrier.name"
                position="is-right"
              >
                <img
                  :alt="carrier.name"
                  :src="getImgURL(carrier.logo)"
                  height="24"
                  width="110"
                >
              </b-tooltip>
            </div>
            <div class="column is-12-mobile is-7-tablet price">
              {{ carrier.price.formatted }}
            </div>
          </li>
          <b-loading
            v-if="carrier_loading && action_to === a.id"
            :is-full-page="false"
            :active="true"
            type="is-primary"
          />
        </ul>
      </li>
    </ul>
    <b-button
      class="mt-5"
      :disabled="
        (selected_address === null && selected_carrier === null) ||
          order_loading
      "
      :loading="order_loading"
      type="is-success"
      @click="updateOrder"
    >
      {{ $t("update") }}
    </b-button>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    countries: {
      type: Array,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    orderAddressId: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      loading: false,
      address: { country: {} },
      updated_address: null,
      deleted_address: null,
      address_form: "",
      action_to: null,
      send_with: null,
      selected_address: null, // index of selected address
      selected_carrier: null,
      carriers: [],
      carriers_loaded: false,
      carrier_loading: false,
      requested_carrier: null,
      order_loading: false
    };
  },
  computed: {
    ...mapGetters({
      list: "getAddresses"
    })
  },
  watch: {
    list(newVal) {
      if (!this.loaded) {
        let address = newVal.find(a => a.id == this.orderAddressId);
        // if (address) this.selected_address = address.id;

        if(address) this.setAddresses(address);

        this.loaded = true;
      }
    }
  },
  mounted: function() {
    this.$bus.$on("reset-checkout-home-address", () => {
      this.selected_address = 0;
      this.selected_carrier = -2;
      this.action_to = -2;
    });

    this.$bus.$on("update-user-address", data => {
      this.closeDrawer(2);
      this.updated_address = data.address.id;
      let self = this;
      setTimeout(function() {
        self.updated_address = null;
      }, 2000);

      if (data.index === -1) {
        this.list.unshift(data.address);
        this.setAddresses(data.address);
      } else {
        this.$set(this.list, data.index, data.address);
        this.setAddresses(data.address);
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("after-save-address");
    this.$bus.$off("reset-checkout-home-address");
    this.$bus.$off("update-user-address");
    this.updateAddresses([]);
  },
  methods: {
    ...mapMutations({
      updateAddresses: "UPDATE_ADDRESSES"
    }),
    create() {
      this.$root.openDrawer("addressForm", {
        index: -1,
        level: 2,
        address: {
          phone: { code: null, number: null },
          country: { iso: null }
        },
        type: "checkout",
        action: "create",
        countries: this.countries,
        userId: this.userId
      });
    },
    update(address, index) {
      let deep_address = JSON.parse(JSON.stringify(address));
      this.$root.openDrawer("addressForm", {
        index: index,
        level: 2,
        address: deep_address,
        type: "checkout",
        action: "update",
        countries: this.countries,
        userId: this.userId,
        order_id: this.orderId,
        from: "orders-page"
      });
    },
    setAddresses(address) {
      //from = null
      this.action_to = address.id;
      this.selected_address = address.id;
      this.carriers_loaded = false;
      this.selected_carrier = null;
      this.$axios
        .get("ecommerce/orders/" + this.orderId + "/carriers/" + address.id)
        .then(res => {
          if (res.data.list.length > 0) {
            this.carriers = res.data.list;
            let selected_carrier = this.carriers.find(s=> s.selected);
            if (this.carriers.length > 0) this.updateCarrier(selected_carrier);
          } else {
            this.$root.notify(this.$i18n.t("carrier_not_found"), "is-danger");
            this.carriers = [];
          }
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.carriers_loaded = true));
    },
    updateCarrier(carrier) {
      this.selected_carrier = carrier.id;
    },
    updateOrder() {
      this.order_loading = true;
      this.$axios
        .patch("ecommerce/orders/" + this.orderId, {
          shipping_address_id: this.selected_address,
          carrier_id: this.selected_carrier
        })
        .then(res => {
          this.$bus.$emit("update-order", {
            summary: res.data.summary,
            addresses: res.data.addresses,
            details: res.data.details
          });
          this.$root.closeDrawer(1);
        })
        .catch(e => {
          //this.selected_carrier = oldcindex;
          this.$root.clientError(e);
        })
        .finally(() => (this.order_loading = false));
    },
    remove(address) {
      this.$axios
        .delete("users/" + this.userId + "/addresses/" + address.id)
        .then(() => {
          let addresses = this.list.filter(a => a.id !== address.id);
          this.updateAddresses(addresses);
        })
        .catch(e => this.$root.clientError(e));
    },
    getImgURL(Img) {
      return `${Img.url}${Img.path}${Img.filename}-w110h24@2x.${Img.version}.webp`;
    },
  }
};
</script>
