<template>
  <div
    v-if="$can('create', 'users') || $can('update', 'users')"
    class="wrap"
  >
    <p class="title is-size-4-mobile mb-5">
      {{ index != -1 ? $t("edit_noun") : $t("create_customer") }}
    </p>
    <UserForm :options="options" />
  </div>
</template>

<script>
import UserForm from "@/components/forms/User";

export default {
  components: {
    UserForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    index() {
      return this.options.index;
    }
  }
};
</script>

