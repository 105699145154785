<template>
  <ValidationObserver
    ref="packObserver"
    v-slot="{ handleSubmit }"
    tag="div"
    class="mt-3"
  >
    <form
      autocomplete="off"
      class="columns is-mobile is-multiline is-variable mb-0"
    >
      <div
        class="
          column
          is-12 is-flex is-justify-content-space-between is-align-items-center
        "
      >
        <b>{{ $t("add_pack") }}</b>

        <div>
          <b-button
            :disabled="new_pack_loading"
            :loading="new_pack_loading"
            size="is-small"
            @click="$emit('close')"
          >
            <b-icon
              icon="close"
              type="is-danger"
              size="is-small"
            />
          </b-button>

          <b-button
            :disabled="new_pack_loading"
            :loading="new_pack_loading"
            size="is-small"
            class="ml-2"
            @click="handleSubmit(saveNewPack)"
          >
            <b-icon
              icon="check"
              type="is-success"
              size="is-small"
            />
          </b-button>
        </div>
      </div>

      <div class="column is-9-tablet is-12-mobile">
        <SearchProduct
          :label="$tc('pack', 1)"
          type="pack"
          size="is-small"
          autofocus
          hide-details
          dense
          :sites-ids="siteId"
          :lang-iso="langIso"
          @selectItem="selectPack"
        />

        <!-- <b-autocomplete-validation
          :key="new_pack.pack_id"
          v-model="new_pack.pack_id"
          rules="required"
          :label="$tc('pack', 1)"
          :data="packs"
          :placeholder="placeholders.pack.name"
          vid="pack"
          item-text="name"
          field="id"
          size="is-small"
          fit-content
          width="100%"
        /> -->
      </div>

      <div class="column is-3-tablet is-12-mobile">
        <b-input-validation
          :key="new_pack.pack_id"
          v-model="new_pack.qty"
          rules="required"
          :label="$t('quantity')"
          :placeholder="placeholders.number['1']"
          vid="qty"
          input-type="number"
          size="is-small"
        />
      </div>

      <div
        v-if="selectedPack && selectedPackCategories.length"
        class="column is-12"
      >
        <SelectPackProducts
          :categories="selectedPackCategories"
          :pack="selectedPack"
          @change="updateNewPackCategories"
        />
      </div>
    </form>
    <hr class="mt-0">
  </ValidationObserver>
</template>

<script>
import SearchProduct from "@/components/forms/elements/searchProduct.vue";
import SelectPackProducts from "@/components/forms/order/SelectPackProducts.vue";

export default {
  components: {
    SearchProduct,
    SelectPackProducts,
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
    langIso: {
      type: [String, Number],
      required: true,
    },
    siteId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      new_pack_loading: false,
      new_pack: {
        pack_id: null,
        qty: 1,
        categories: [],
      },
      packs: [],
      selectedPackCategories: [],
      selectedPack: null,
    };
  },
  watch: {
    // "new_pack.pack_id"(newVal) {
    //   this.selectedPack = null;

    //   if (newVal) {
    //     this.selectedPack = this.packs.find((p) => p.id == newVal);
    //     this.getPackCategories(newVal);
    //   }
    // },
  },
  created() {
    // this.getPacks();
  },
  methods: {
    updateNewPackCategories(value) {
      this.new_pack.categories = value;
    },
    // getPacks() {
    //   let siteFilter = `?filters=${btoa(
    //     JSON.stringify([
    //       { id: "site_id", selected: this.siteId, operator: "is" },
    //       { id: "lang_iso", selected: this.langIso, operator: "is" },
    //     ])
    //   )}`;
    //   this.$axios
    //     .get("packs" + siteFilter)
    //     .then((res) => {
    //       this.packs = res.data.list;
    //       this.show_new_pack_loaded = true;
    //     })
    //     .catch((e) => {
    //       this.clientError(e);
    //       this.show_new_pack_form = false;
    //     });
    // },
    selectPack(pack) {
      this.selectedPack = null;

      if (pack && pack.id) {
        this.selectedPack = pack;
        this.getPackCategories(pack.id);
      }
    },
    getPackCategories(pack_id) {
      this.$axios
        .get(`ecommerce/orders/${this.orderId}/pack/${pack_id}/categories`)
        .then((res) => {
          this.selectedPackCategories = res.data.categories;
          this.new_pack.pack_id = pack_id;
        })
        .catch((e) => this.clientError(e));
    },
    saveNewPack() {
      let params = {
        pack_id: this.new_pack.pack_id,
        qty: this.new_pack.qty ? parseInt(this.new_pack.qty) : 1,
        items: this.new_pack.categories.reduce((arr, c) => {
           return [...arr, c.sku];
         }, []),
      };
      this.new_pack_loading = true;
      this.$axios
        .post("ecommerce/orders/" + this.orderId, params)
        .then((res) => {
          this.$emit('update', res);
          this.new_pack = {
            pack_id: null,
            qty: 1,
            categories: [],
          };
          this.selectedPack = null;
          this.$refs.packObserver.reset();
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => {
          this.new_pack_loading = false;
          // this.show_new_pack_form = false;
        });
    },
  },
};
</script>

<style>
</style>
