<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myProfile.fname"
            rules="required|min:2"
            maxlength="30"
            :label="$t('fname')"
            vid="fname"
            :placeholder="placeholders.user.fname"
            :disabled="disabledItems"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myProfile.lname"
            rules="required|min:2"
            maxlength="30"
            :label="$t('lname')"
            vid="lname"
            :placeholder="placeholders.user.lname"
            :disabled="disabledItems"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myProfile.email"
            rules="required"
            :label="$t('email')"
            vid="email"
            :placeholder="placeholders.user.email"
            :disabled="disabledItems"
          />
        </div>
        <div class="column is-3-tablet is-12-mobile">
          <b-select-validation
            v-model="myProfile.lang_iso"
            rules="required"
            vid="language"
            :label="$tc('language', 1)"
            :disabled="disabledItems"
          >
            <template>
              <option
                v-for="opt in languages"
                :key="opt.iso"
                :value="opt.iso"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-3-tablet is-12-mobile">
          <b-select-validation
            v-model="myProfile.role.id"
            rules="required"
            vid="role"
            :label="$tc('role', 1)"
            :disabled="disabledItems"
          >
            <template>
              <option
                v-for="opt in roles"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.label
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            vid="warehouse_id"
            name="warehouse_id"
            rules="required"
          >
            <b-field
              :label="$tc('warehouse', 1)"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-taginput
                ref="taginput"
                v-model="myProfile.warehouses"
                rules="required"
                :data="mappedWarehouses"
                :placeholder="
                  myProfile.warehouses.length == 0 ? $t('choose') : ''
                "
                :disabled="disabledItems"
                autocomplete
                open-on-focus
                ellipsis
                aria-close-label="Delete this tag"
                @add="
                  obj => {
                    filterWarehouses('');
                  }
                "
                @remove="
                  obj => {
                    filterWarehouses('');
                  }
                "
                @typing="filterWarehouses"
              >
                <span
                  slot-scope="props"
                >{{ props.option.country.country }} -
                  {{ props.option.currency.code }}</span>
                <template
                  slot="selected"
                  slot-scope="props"
                >
                  <b-tag
                    v-for="(tag, i) in props.tags"
                    :key="i"
                    type="is-default"
                    :tabstop="false"
                    closable
                    @close="$refs.taginput.removeTag(i, $event)"
                  >
                    {{ tag.country.country }} - {{ tag.currency.code }}
                  </b-tag>
                </template>
              </b-taginput>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-autocomplete-validation
            v-model="myProfile.supplier_id"
            :label="`${$tc('supplier', 1)} (${$t('optional')})`"
            :disabled="disabledItems"
            :placeholder="placeholders.supplier.name"
            clearable
            :data="suppliers"
            vid="supplier_id"
            item-text="label"
            field="id"
          />
        </div>
        <div class="column pt-0">
          <b-button
            type="is-primary"
            :disabled="update_loading || disabledItems"
            :loading="update_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider
  },
  props: {
    action: {
      type: String,
      required: true
    },
    profile: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    suppliers: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      myProfile: { ...this.profile },
      update_loading: false,
      disabledItems: this.action == "read",
      mappedWarehouses: []
    };
  },
  created() {
    if (this.profile.id) {
      this.myProfile.warehouses = this.myProfile.warehouses.reduce((arr, w) => {
        let obj = this.warehouses.find(l => l.id == w);
        if (obj) return [...arr, obj];
        return arr;
      }, []);
      this.myProfile.supplier_id = this.profile.supplier.id;
      this.myProfile.lang_iso = this.profile.language.iso;
    }

    this.mappedWarehouses = this.warehouses;

    this.filterWarehouses("");
  },
  methods: {
    filterWarehouses(search) {
      let filtered = this.warehouses.filter(option => {
        return (
          JSON.stringify(option.country.country + option.currency.code)
            .toLowerCase()
            .includes(search.toLowerCase()) &&
          this.myProfile.warehouses.findIndex(s => s.id == option.id) == -1
        );
      });

      this.mappedWarehouses = filtered;
    },
    save() {
      this.update_loading = true;

      let formData = {
        role_id: this.myProfile.role.id,
        lang_iso: this.myProfile.lang_iso,
        fname: this.myProfile.fname,
        supplier_id: this.myProfile.supplier_id,
        lname: this.myProfile.lname,
        email: this.myProfile.email,
        warehouses: this.myProfile.warehouses.reduce(
          (arr, w) => [...arr, w.id],
          []
        )
      };

      let url = "agents";
      let method = "POST";
      if (this.action === "update") {
        url += `/${this.profile.id}`;
        method = "PUT";
      }

      this.$axios({
        method: method,
        url: url,
        data: formData
      })
        .then(({ data }) => {
          if (this.action === "update") {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else if (this.action === "create") {
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
          this.$bus.$emit("update-agent", data);
          this.closeDrawer();
        })
        .catch(e => {
          this.clientError(e);
        })
        .finally(() => {
          this.update_loading = false;
        });
    }
  }
};
</script>

