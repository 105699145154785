<template>
  <ValidationObserver
    v-if="$can('create', 'users.business') || $can('update', 'users.business')"
    ref="observer"
    v-slot="{ handleSubmit }"
    div="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myCompany.address.fname"
            rules="required|min:2"
            maxlength="30"
            :label="$t('fname')"
            vid="fname"
            :placeholder="placeholders.user.fname"
          />
        </div>
        <div class="column is-6-tablet is-7-mobile">
          <b-input-validation
            v-model="myCompany.address.lname"
            rules="required|min:2"
            maxlength="30"
            :label="$t('lname')"
            vid="lname"
            :placeholder="placeholders.user.lname"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myCompany.company"
            rules="required"
            :label="$t('company')"
            maxlength="35"
            vid="company"
            :disabled="
              company.company &&
                !$can('update', 'users.business.override') && 
                options.status !== 'awaiting'
            "
            :placeholder="placeholders.company.name"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-field addons>
            <p class="control">
              <b-autocomplete-validation
                v-model="myCompany.address.phone.code"
                rules="required"
                fit-content
                :expanded="true"
                :label="$t('country_code')"
                :data="countries"
                :placeholder="placeholders.user.phone.code"
                vid="country_code"
                item-text="code"
                item-text2="country"
                field="code"
                type="phone"
              />
            </p>
            <p class="control is-expanded">
              <b-input-validation
                v-model="myCompany.address.phone.number"
                rules="required"
                sexpanded
                :label="$t('phone')"
                vid="phone"
                input-type="tel"
                :placeholder="placeholders.user.phone.number"
              />
            </p>
          </b-field>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myCompany.address.address1"
            rules="required"
            maxlength="35"
            :label="$t('address1')"
            vid="address1"
            :placeholder="placeholders.address.line1"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myCompany.address.address2"
            :label="`${$t('address2')} (${$t('optional')})`"
            vid="address2"
            maxlength="35"
            :placeholder="placeholders.address.line2"
          />
        </div>
        <div class="column is-2-tablet is-4-mobile pr-0">
          <b-input-validation
            v-model="myCompany.address.zip"
            rules="required|min:2"
            maxlength="15"
            :label="$t('zip')"
            vid="zip"
            :placeholder="placeholders.address.zip"
          />
        </div>
        <div class="column is-4-tablet is-8-mobile">
          <b-input-validation
            v-model="myCompany.address.city"
            rules="required"
            maxlength="35"
            :label="$t('city')"
            vid="city"
            :placeholder="placeholders.address.city"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-autocomplete-validation
            v-model="myCompany.address.country.iso"
            rules="required"
            :expanded="true"
            :label="$tc('country', 1)"
            :data="countries"
            :placeholder="placeholders.user.phone.country"
            :disabled="
              company.address &&
                company.address.country &&
                company.address.country &&
                !$can('update', 'users.business.override') &&
                options.status !== 'awaiting'
            "
            vid="country"
            item-text="country"
            field="iso"
            type="country"
          />
        </div>
        <div class="column is-6-tablet is-7-mobile">
          <b-select-validation
            v-model="myCompany.incoterm"
            :disabled="!$can('update', 'orders.factoring')"
            :label="`${$t('incoterm')} (${$t('optional')})`"
          >
            <template>
              <option
                v-for="opt in incoterms"
                :key="opt"
                :value="opt"
              >
                {{ opt }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-6-tablet is-5-mobile">
          <b-select-validation
            v-model="myCompany.factoring"
            :disabled="!$can('update', 'orders.factoring')"
            :label="`${$t('factoring')} (${$t('optional')})`"
          >
            <template>
              <option
                v-for="opt in factorings"
                :key="opt"
                :value="opt"
              >
                {{ opt }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-6-tablet is-7-mobile">
          <b-input-validation
            v-model="myCompany.vat_num"
            :label="`${$t('vat_num')} (${$t('optional')})`"
            vid="vat_num"
            :placeholder="placeholders.company.vat_num"
            :message="vatMessage"
            :disabled="
              company.vat &&
                company.vat.num &&
                !$can('update', 'users.business.override') &&
                options.status !== 'awaiting'
            "
            :prefix="myCompany.vat_iso"
            :suffix="myCompany.vat_checker"
          >
            <b-button
              slot="suffix"
              :key="vatVerified"
              expanded
              :disabled="update_loading || !myCompany.vat_num"
              :loading="update_loading"
              outlined
              @click="verifyVatNum"
            >
              {{ $t("verify") }}
            </b-button>
          </b-input-validation>
        </div>

        <div class="column is-6-tablet is-3-mobile">
          <b-switch
            v-model="myCompany.vat_exempted"
            class="mt-2"
            :disabled="disableExempted()"
          >
            {{ $t("exempted") }}
          </b-switch>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myCompany.type_id"
            rules="required"
            vid="type_account"
            :label="$tc('type', 1)"
          >
            <template>
              <option
                v-for="opt in accountTypes"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myCompany.lang_iso"
            vid="language"
            :placeholder="$t('no_label')"
            :label="$tc('label_language', 1)"
          >
            <template>
              <option
                v-for="opt in languages"
                :key="opt.iso"
                :value="opt.iso"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div
          v-if="options.site.type == 'b2b'"
          class="column is-6-tablet is-12-mobile"
        >
          <b-input-validation
            v-model="myCompany.invoice.email"
            :label="$t('invoice_email') + ' (' + $t('optional') + ')'"
            vid="invoice_email"
            maxlength="50"
            :placeholder="placeholders.user.email"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile mt-2">
          <b-switch v-model="myCompany.invoice.hide_name">
            {{ $t("hide_name_in_invoice") }}
          </b-switch>
        </div>

        <div class="column">
          <b-button
            type="is-primary"
            :disabled="update_loading"
            :loading="update_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      company: this.options.company || {},
      local_iso: this.options.local_iso || {},
      myCompany: {
        company: "",
        invoice: {
          hide_name: false,
          email: "",
        },
        factoring: null,
        incoterm: null,
        address: {
          address1: "",
          address2: "",
          zip: "",
          city: "",
          country: {},
          phone: {},
        },
        vat_num: "",
        vat_exempted: false,
      },
      vatVerified: true,
      vatMessage: "",
      incoterms: this.options.incoterms || [],
      update_loading: false,
      factorings: [30, 45, 60],
      local: false,
    };
  },
  computed: {
    countries() {
      return this.options.countries;
    },
    userId() {
      return this.options.userId;
    },
    languages() {
      return this.options.languages || [];
    },
    accountTypes() {
      return this.options.account_types || [];
    },
  },
  watch: {
    "myCompany.vat_num"(newValue) {
      if (newValue != (this.company.vat && this.company.vat.num)) {
        this.vatVerified = null;
      }
    },
    "myCompany.address.country.iso"(newValue) {
      let country = this.countries.find((c) => c.iso == newValue);
      if (country) {
        this.myCompany.vat_iso = country.vat_iso;
        this.myCompany.vat_checker = country.vat_checker;
        if (this.myCompany.vat_iso == this.local_iso) {
          this.local = true;
          this.myCompany.vat_exempted = false;
        }
        this.vatVerified = null;
      }
    },
  },
  created() {
    this.vatVerified =
      this.options.company &&
      this.options.company.vat &&
      this.options.company.vat.verified;

    if (this.company.company) {
      this.myCompany = JSON.parse(JSON.stringify(this.company));
      this.myCompany.vat_num = this.company.vat.num.value;
      this.myCompany.vat_iso = this.company.vat.iso;
      if (this.myCompany.vat_iso == this.local_iso) this.local = true;
      this.myCompany.vat_exempted = this.company.vat.exempted;

      if (this.company.vat.verified) this.vatMessage = this.$t("vies_valid");
      else if (this.company.vat.verified != null) this.vatMessage = this.$t("vies_invalid");

      this.myCompany.invoice = {
        hide_name: this.company.invoice
          ? this.company.invoice.hide_name
          : false,
        email: this.company.invoice ? this.company.invoice.email : "",
      };
      if (this.company.language)
        this.myCompany.lang_iso = this.company.language.iso;
      delete this.myCompany.vat;
      delete this.myCompany.address.country.code;
      delete this.myCompany.address.country.country;
      delete this.myCompany.language;
    }
  },
  methods: {
    disableExempted() {
      if (this.options.status == "awaiting") return false;
      
      if (this.$can("update", "users.business.override")) return false;

      if (this.local) return true;

      if (this.company.vat && this.company.vat.verified) return false;

      if (this.vatVerified) return false;

      return true;
    },
    verifyVatNum() {
      this.update_loading = true;
      this.vatMessage = "";

      this.$axios
        .get(
          `users/${this.userId}/company/vat/${this.myCompany.vat_iso}/${this.myCompany.vat_num}/check`
        )
        .then((res) => {
          this.vatVerified = true;
          this.vatMessage = this.$t("vies_valid");
        })
        .catch((e) => {
          this.clientError(e);
          this.vatMessage = this.$t("vies_invalid");
        })
        .finally(() => (this.update_loading = false));
    },
    save() {
      if (this.$can("update", "users.business")) {
        this.update_loading = true;
        let copy_company = JSON.parse(JSON.stringify(this.myCompany));

        if (copy_company.factoring === undefined) copy_company.factoring = null;

        copy_company.invoice_hide_name = copy_company.invoice.hide_name;
        copy_company.invoice_email = copy_company.invoice.email;
        delete copy_company.invoice;

        if (!this.$can("update", "orders.factoring")) {
          delete copy_company.factoring;
          delete copy_company.incoterm;
        }

        if (!copy_company.lang_iso) copy_company.lang_iso = null;

        delete copy_company.address.company;
        let method = this.company.company ? "put" : "post";
        this.$axios({
          method: method,
          url: "users/" + this.userId + "/company",
          data: copy_company,
        })

          .then((res) => {
            this.closeDrawer("main");
            this.$bus.$emit("update-company", res.data);
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          })
          .catch((e) => this.$root.clientError(e))
          .finally(() => (this.update_loading = false));
      }
    },
  },
};
</script>

