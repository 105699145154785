var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.$can('read', 'orders'))?_c('div',{staticClass:"wrap"},[_c('p',{staticClass:"title is-size-4-mobile mb-5"},[_vm._v(" "+_vm._s(_vm.$tc("order", 1))+" "),_c('span',{staticClass:"has-text-grey-light"},[_vm._v(" #"+_vm._s(_vm.mpId ? _vm.mpId : _vm.options.order_id)+" ")])]),(_vm.details && _vm.payments && _vm.summary && _vm.$can('read', 'orders'))?_c('Form',{attrs:{"index":_vm.options.index,"order-id":_vm.options.order_id,"options":{ details: _vm.details, payments: _vm.payments, summary: _vm.summary },"incoterms":_vm.incoterms,"mergeable_with":_vm.mergeable_with,"mp-id":_vm.options.mp_id}}):_vm._e(),(_vm.addresses && _vm.items && _vm.summary && _vm.$can('read', 'orders'))?_c('Details',{attrs:{"options":{
      addresses: _vm.addresses,
      orderId: _vm.options.order_id,
      userId: _vm.options.user_id,
      items: _vm.items,
      summary: _vm.summary,
      carrier: _vm.details.carrier,
      site_id: _vm.site.id,
      warehouse_id: _vm.warehouse.id,
      lang_iso: _vm.lang.iso
    },"incoterm":_vm.details.incoterm,"notes":_vm.details.notes,"editable":_vm.editable,"editable-pricing":_vm.editablePricing,"count":_vm.count,"warehouses":_vm.options.warehouses}}):_vm._e(),_c('b-modal',{attrs:{"width":520,"height":520},model:{value:(_vm.showShippedModal),callback:function ($$v) {_vm.showShippedModal=$$v},expression:"showShippedModal"}},[_c('Card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"is-size-5 px py-4"},[_c('b',[_vm._v(_vm._s(_vm.$t("shipping_info")))])]),_c('hr',{staticClass:"my-0"})]),_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('b-input-validation',{staticClass:"column is-12",attrs:{"label":((_vm.$t('tracking_number')) + " (" + (_vm.$t('optional')) + ")"),"placeholder":_vm.placeholders.order.tracking_num},model:{value:(_vm.tracking_num),callback:function ($$v) {_vm.tracking_num=$$v},expression:"tracking_num"}}),_c('b-switch',{staticClass:"column is-12 pt-0",model:{value:(_vm.notify_customer),callback:function ($$v) {_vm.notify_customer=$$v},expression:"notify_customer"}},[_vm._v(" "+_vm._s(_vm.$t("notify_customer"))+" ")]),_c('div',{staticClass:"column is-12"},[_c('b-button',{staticClass:"mr-4",attrs:{"type":"is-success","tag":"input","value":_vm.$t('validate'),"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.saveShippedStatusInfo()}}}),_c('b-button',{on:{"click":function($event){_vm.showShippedModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)])],1)],1):_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})}
var staticRenderFns = []

export { render, staticRenderFns }