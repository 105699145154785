<template>
  <li
    :key="'store-' + store.ref"
    v-bind:class="hovered === store.ref ? 'active' : null"
    @mouseenter="onStoreHover"
    @mouseleave="hovered = null"
    @mouseover="
      () => {
        hovered = store.ref;
        $emit('onStoreHover', store.ref);
      }
    "
  >
    <div class="wrap">
      <b>{{ store.name }}</b>
      <div>{{ store.address.address1 }}</div>
      <div v-if="store.address.address2">
        {{ store.address.address2 }}
      </div>
      <div>{{ store.address.city }}, {{ store.address.zip }}</div>
      <div class="opening">
        <b
          class="link is-clickable"
          @click="show_hours = !show_hours"
        >{{ $t("opening_hours") }}
          <b-icon
            icon="chevron-down"
            size="is-small"
          />
        </b>

        <ul v-if="show_hours">
          <li
            v-for="(opening, o) in store.opening_hours"
            :key="o"
          >
            <span class="day">{{ opening.day }}</span>
            <span
              v-if="opening.open.length > 0"
              class="hours"
            >
              <span>{{
                opening.open[0]
                  ? opening.open[0]["from"] + " - " + opening.open[0]["to"]
                  : ""
              }}</span>
              <span v-if="opening.open[1]">{{
                opening.open[1]
                  ? opening.open[1]["from"] + " - " + opening.open[1]["to"]
                  : ""
              }}</span>
            </span>
            <span v-else>{{ $t("closed") }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="choose">
      <b-button
        :loading="choose_loading"
        :disabled="choose_loading"
        expanded
        size="is-small"
        color="is-info"
        class="mt-3"
        @click="choose()"
      >
        {{ $t("choose") }}
      </b-button>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    store: {
      type: Object,
      required: true
    },
    hoveredOnStore: {
      type: Object,
      default: null
    },
    selectedStore: {
      type: String,
      default: null
    },
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      show_hours: false,
      hovered: this.hoveredOnStore,
      choose_loading: false
    };
  },
  methods: {
    onStoreHover() {
      this.hovered = this.store.ref;
      //   this.$store.dispatch("updateSelectedStore", this.store.ref);
      //this.$bus.$emit("onStoreHover", this.store.ref);
    },
    choose() {
      this.choose_loading = true;
      this.$axios
        .get("ecommerce/orders/" + this.orderId + "/pickup/" + this.store.ref)
        .then(res => {
          this.$bus.$emit("update-order", {
            summary: res.data.summary,
            addresses: res.data.addresses
          });
          this.$root.closeDrawer(1);
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.choose_loading = false));
    }
  }
};
</script>
