<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <VoucherForm
      :key="options.voucher ? options.voucher.id : 0"
      :default-site-id="options.default_site_id"
      :index="options.index"
      :voucher="options.voucher"
      :sites="options.sites"
      :categories="options.categories"
      :brands="options.brands"
      :user-id="options.user_id"
      :action="action"
      :from="options.from"
    />
  </div>
</template>

<script>
import VoucherForm from "@/components/forms/Voucher";

export default {
  components: {
    VoucherForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "",
      action: ""
    };
  },
  created() {
    if (this.options.action == "read") {
      if (this.$can("update", "orders.vouchers")) {
        this.action = "update";
        this.title = this.$t("edit_noun");
      } else if (this.$can("read", "orders.vouchers")) {
        this.action = "read";
        this.title = this.$tc("voucher", 1);
      }
    } else {
      this.action = "create";
      this.title = this.$t("create_voucher");
    }
  }
};
</script>

