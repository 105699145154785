<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ index != -1 ? $t("edit_noun") : $t("create_address") }}
    </p>
    <AddressForm
      :index="options.index"
      :user-id="options.userId"
      :order-id="options.order_id"
      :address="options.address"
      :countries="options.countries"
      :from="options.from"
    />
  </div>
</template>

<script>
import AddressForm from "@/components/forms/Address";

export default {
  components: {
    AddressForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    index() {
      return this.options.index;
    }
  }
};
</script>
