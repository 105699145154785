<template>
  <div>
    <StoreSearch :order-id="orderId" />
  </div>
</template>
<script>
import StoreSearch from "./StoreSearch";

export default {
  components: { StoreSearch },
  props: {
    orderId: {
      type: Number,
      required: true
    }
  }
};
</script>
