<template>
  <div
    v-if="!success"
    id="PaymentForm"
    class="payment-container"
  />
</template>

<script>
import KRGlue from "@lyracom/embedded-form-glue";

export default {
  props: {
    formToken: {
      type: String,
      required: true
    },
    publicKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      endpoint: "https://api.payzen.eu",
      success: false
    };
  },
  mounted() {
    KRGlue.loadLibrary(this.endpoint, this.publicKey, this.formToken) // Load the remote library
      .then(({ KR }) =>
        KR.setFormConfig({
          // set the minimal configuration
          "kr-language": "fr",
          formToken: this.formToken
        })
      )
      .then(({ KR }) => KR.addForm("#PaymentForm")) // create a payment form
      .then(({ KR, result }) => KR.showForm(result.formId)) // show the payment form
      .then(({ KR }) => {
        return KR.onSubmit(response => {
          if (response.clientAnswer.orderStatus === "PAID") {
            this.onSubmit(response.clientAnswer.orderDetails.orderId);
            this.success = true;
          }
        });
      })
      .then(({ KR }) => {
        return KR.onError(error => {
          let code = error.errorCode;
          let message = error.errorMessage;
          this.onError(code, message);
        });
      });
  },
  beforeDestroy() {
    window.KR.removeForms();
  },
  methods: {
    onSubmit(event) {
      this.$emit("onSubmit", event);
    },
    onError(code) {
      this.$emit("onError", code);
    }
  }
};
</script>

<style>
.payment-container {
  display: flex;
  justify-content: center;
  min-height: 330px;
  padding: 24px;
}
</style>
