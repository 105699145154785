<template>
  <div
    v-if="color === 'success'"
    class="swa swa-success animate"
  >
    <span class="swa-line swa-tip animateSuccessTip" />
    <span class="swa-line swa-long animateSuccessLong" />
    <div class="swa-placeholder" />
    <div class="swa-fix" />
  </div>
  <div
    v-else-if="color === 'error'"
    class="swa swa-error animate"
  >
    <span class="swa-x-mark">
      <span class="swa-line swa-left animateXLeft" />
      <span class="swa-line swa-right animateXRight" />
    </span>
    <div class="swa-placeholder" />
    <div class="swa-fix" />
  </div>
  <div
    v-else
    class="swa swa-warning scaleWarning"
  >
    <span class="swa-body pulseWarningIns" />
    <span class="swa-dot pulseWarningIns" />
  </div>
</template>
<script>
export default {
  name: "SweetAlert",
  props: {
    color: {
      type: String,
      required: true
    }
  }
};
</script>
