<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ $t("new_agent") }}
    </p>
    <AgentForm
      :key="options.key"
      action="create"
      :profile="profile"
      :languages="options.languages"
      :roles="options.roles"
      :suppliers="options.suppliers"
      :warehouses="options.warehouses"
    />
  </div>
</template>

<script>
import AgentForm from "@/components/forms/Agent";

export default {
  components: {
    AgentForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      profile: {
        fname: null,
        lname: null,
        email: null,
        role: {
          id: null
        },
        lang_iso: null,
        supplier_id: null,
        active: true,
        warehouses: []
      }
    };
  }
};
</script>
