<template>
  <ValidationObserver
    v-if="$can('update', 'users.cashback')"
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-4-tablet is-6-mobile">
          <b-input-validation
            v-model="myCashback.amount"
            rules="required"
            input-type="number"
            step=".01"
            :label="$t('amount')"
            vid="amount"
            :disabled="action === 'r'"
            :placeholder="placeholders.price.decimal"
          />
        </div>
        <div class="column is-2-tablet is-6-mobile">
          <b-select-validation
            v-model="myCashback.currency_code"
            rules="required"
            :label="$t('currency_code')"
            vid="currency_code"
            :disabled="action === 'r'"
          >
            <template>
              <option
                v-for="opt in currencies"
                :key="opt.code"
                :value="opt.code"
              >
                {{ opt.code }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myCashback.site_id"
            rules="required"
            :label="$t('site')"
            vid="site"
            disabled
          >
            <template>
              <option
                v-for="opt in sites"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.label
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-6-tablet is-6-mobile">
          <b-select-validation
            v-model="myCashback.order_id"
            :label="`${$tc('order', 1)} (${$t('optional')})`"
            vid="order_id"
            :disabled="action === 'r'"
          >
            <template>
              <option
                v-for="opt in orders"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.id
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-4-tablet is-6-mobile">
          <b-datepicker-validation
            v-model="myCashback.expire_at"
            :label="$t('expire_at')"
            rules="required"
            vid="expire_at"
            :disabled="action === 'r'"
            :min-date="min_date"
          />
        </div>
        <div class="column is-2-tablet is-12-mobile mt-2">
          <b-switch
            v-model="myCashback.active"
            :disabled="action === 'r'"
          >
            {{
              $tc("active", 1)
            }}
          </b-switch>
        </div>

        <div class="column">
          <b-button
            type="is-primary"
            :disabled="save_loading || action === 'r'"
            :loading="save_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import moment from "moment";
export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    defaultSiteId: {
      type: Number,
      required: true
    },
    cashback: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    orders: {
      type: Array,
      default: () => []
    },
    userId: {
      type: Number,
      default: null
    },
    action: {
      type: String,
      default: "cu" // cu : create+update
    }
  },
  data() {
    return {
      save_loading: false,
      cashback_types: [
        { type: "percentage", name: this.$i18n.t("percentage") },
        { type: "shipping", name: this.$i18n.t("free_shipping") }
      ],
      menu: false,
      myCashback: {
        order_id: null,
        used_order_id: null,
        site_id: null,
        amount: null,
        currency_code: null,
        expire_at: null,
        active: true
      },
      site: this.sites.find(element => element.id == this.defaultSiteId),
      site_id: 0
    };
  },
  computed: {
    min_date() {
      return moment()
        .add(1, "years")
        .format("YYYY-MM-DD");
    },
    method() {
      if (this.index === -1) return "post";
      else return "put";
    },
    currencies() {
      return this.site ? this.site.currencies : [];
    }
  },
  watch: {
    "myCashback.site_id": function(new_value) {
      this.site_id = new_value;
    }
  },
  created() {
    if (this.cashback.site_id !== undefined)
      this.site_id = this.cashback.site_id;

    this.myCashback.order_id = this.cashback.order_id;
    this.myCashback.used_order_id = this.cashback.used_order_id;
    this.myCashback.site_id = this.defaultSiteId;
    this.myCashback.amount = this.cashback.amount
      ? this.cashback.amount.value
      : null;

    this.myCashback.currency_code = this.cashback.currency_code
      ? this.cashback.currency_code
      : null;

    this.myCashback.used = this.cashback.used ? this.cashback.used : false;

    this.myCashback.active =
      this.cashback.active !== undefined
        ? this.cashback.active
        : this.myCashback.active;

    if (this.cashback.expire_at) {
      let d = moment.parseZone(this.cashback.expire_at.datetime);
      this.myCashback.expire_at = d.format("YYYY-MM-DD");
    }

    this.myCashback.currency_code =
      this.index === -1 && this.site.currencies
        ? this.site.currencies[0]["code"]
        : this.cashback.currency_code;
  },
  mounted() {
    if (!this.cashback.expire_at) this.myCashback.expire_at = this.min_date;
  },
  methods: {
    save() {
      if (this.$can("read", "users.cashback")) {
        let self = this;
        self.save_loading = true;
        let cashback_id = this.cashback.id ? "/" + this.cashback.id : "";
        if (this.userId) this.myCashback.user_id = this.userId;
        this.$axios({
          method: self.method,
          url: "users/" + this.userId + "/cashback" + cashback_id,
          data: self.myCashback
        })
          .then(res => {
            self.$bus.$emit("save-cashback", {
              index: self.index,
              cashback: res.data
            });
            self.closeDrawer("main");
          })
          .catch(e => {
            this.clientError(e);
          })
          .finally(() => (self.save_loading = false));
      }
    }
  }
};
</script>

