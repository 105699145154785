<template>
  <div class="wrap">
    <b-tabs
      expanded
      class="paddingless"
    >
      <b-tab-item
        :label="$t('home')"
        icon="home"
      >
        <AddressList
          :countries="countries"
          :order-id="options.order_id"
          :user-id="options.user_id"
          :order-address-id="options.address.id"
        />
      </b-tab-item>
      <b-tab-item
        :label="$t('pickup_point')"
        icon="map-marker"
      >
        <StoreLocator :order-id="options.order_id" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import AddressList from "@/components/forms/order/AddressList";
import StoreLocator from "@/components/forms/order/StoreLocator";
import { mapMutations } from "vuex";

export default {
  components: {
    AddressList,
    StoreLocator
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      addresses: [],
      countries: [],
      showPickup: true,
      triggerAddresses: 0,
      tab: 0
    };
  },
  created() {
    this.$axios
      .get("ecommerce/orders/" + this.options.order_id + "/addresses")
      .then(res => {
        this.updateAddresses(res.data.addresses);
        this.countries = res.data.countries;
        if (res.data.pickup !== undefined && res.data.countries === false)
          this.showPickup = false;
        if (this.addresses.length > 0) {
          return this.addresses.map(a => {
            var o = Object.assign({}, a);
            o.oid = a.id;
            return o;
          });
        } else {
          return [];
        }
      })
      .catch(e => this.clientError(e))
      .finally(() => {});
  },
  methods: {
    ...mapMutations({
      updateAddresses: "UPDATE_ADDRESSES"
    })
  }
};
</script>

