<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <DealForm
      :key="options.key"
      :index="options.index"
      :deal="options.deal"
      :sites="options.sites"
      :brands="options.brands"
      :warehouses="options.warehouses"
      :user-id="options.user_id"
      :action="action"
      :from="options.from"
    />
  </div>
</template>

<script>
import DealForm from "@/components/forms/Deal";

export default {
  components: {
    DealForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "",
      action: ""
    };
  },
  created() {
    if (this.options.action == "read") {
      if (this.$can("update", "catalog.deals")) {
        this.action = "update";
        this.title = this.$t("edit_noun");
      } else if (this.$can("read", "catalog.deals")) {
        this.action = "read";
        this.title = this.$tc("deal", 1);
      }
    } else if (this.$can("create", "catalog.deals")) {
      this.action = "create";
      this.title = this.$t("new_deal");
    }
  }
};
</script>

