<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <CashbackForm
      v-if="$can('update', 'users.cashback')"
      :key="options.cashback ? options.cashback.id : 0"
      :default-site-id="options.default_site_id"
      :index="options.index"
      :cashback="options.cashback"
      :sites="options.sites"
      :orders="options.orders"
      :user-id="options.user_id"
      :action="options.action"
    />
  </div>
</template>

<script>
import CashbackForm from "@/components/forms/Cashback";

export default {
  components: {
    CashbackForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: null
    };
  },
  created() {
    if (this.options.action === "r") {
      this.title = this.$tc("cashback", 1);
    } else {
      if (this.options.index !== -1) {
        this.title = this.$t("edit_noun");
      } else {
        this.title = this.$t("create_cashback");
      }
    }
  }
};
</script>
