var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-2 is-multiline is-mobile"},[_vm._l((_vm.formatedItems),function(category,index){return [_c('b-autocomplete-validation',{key:("category-" + (category.id) + "-" + index + "-product-" + (category.product_permalink)),staticClass:"column is-6-tablet is-12-mobile",attrs:{"label":((_vm.$tc('product', 1)) + " " + (index + 1) + ", " + (_vm.$tc('category', 1)) + " " + (category.label)),"placeholder":_vm.placeholders.product.name,"data":category.products,"vid":("product-" + (category.id) + "-" + index),"rules":"required","size":"is-small","item-text":"name","field":"permalink","width":"100%","fit-content":""},on:{"change":function (permalink) { return _vm.selectSizes(permalink, index); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var props = ref.props;
return [_c('span',[_vm._v(_vm._s(props.option.name))])]}}],null,true),model:{value:(category.product_permalink),callback:function ($$v) {_vm.$set(category, "product_permalink", $$v)},expression:"category.product_permalink"}}),_c('b-autocomplete-validation',{key:("category-" + (category.id) + "-" + index + "-size-" + (category.product_permalink)),staticClass:"column is-3-tablet is-12-mobile",attrs:{"label":_vm.$tc('size', 1),"disabled":!category.product_permalink,"placeholder":_vm.placeholders.product.name,"data":category.sizes || [],"vid":("size-" + (category.id) + "-" + index),"rules":"required","size":"is-small","item-text":"size","field":"size","width":"100%","fit-content":""},on:{"change":function (size) { return _vm.selectAttributes(size, category.product_permalink, index); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var props = ref.props;
return [_c('span',[_vm._v(_vm._s(props.option.size))])]}}],null,true),model:{value:(category.size_id),callback:function ($$v) {_vm.$set(category, "size_id", $$v)},expression:"category.size_id"}}),(_vm.canDisplaySku(category))?_c('SizeAutocomplete',{key:("category-" + (category.id) + "-" + index + "-sku-" + (category.product_permalink) + "-" + (category.size_id)),staticClass:"column is-3-tablet is-12-mobile",attrs:{"label":_vm.getSkuLabel(category),"disabled":!category.product_permalink || !category.size_id,"data":category.items || [],"vid":("attribute-" + (category.id) + "-" + index),"rules":"required","size":"is-small","item-text":category.items && category.items[0].attributes.flavors
          ? 'attributes.flavors.value'
          : 'attributes.colors.value',"item-id":"id","item-search":category.items && category.items[0].attributes.flavors
          ? 'attributes.flavors.value'
          : 'attributes.colors.value',"fit-content":"","width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var props = ref.props;
return [_c('span',[_vm._v(_vm._s((props.option.attributes.flavors && props.option.attributes.flavors.value) || (props.option.attributes.colors && props.option.attributes.colors.value)))])]}}],null,true),model:{value:(category.sku),callback:function ($$v) {_vm.$set(category, "sku", $$v)},expression:"category.sku"}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }