<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ $t("change_password") }}
    </p>
    <ResetPasswordForm :options="options" />
  </div>
</template>

<script>
import ResetPasswordForm from "@/components/forms/ResetPassword";

export default {
  components: {
    ResetPasswordForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    index() {
      return this.options.index;
    }
  }
};
</script>

