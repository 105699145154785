<template>
  <div class="wrap">
    <b-loading
      v-if="loading"
      :is-full-page="false"
      :active="true"
    />
    <payment
      v-else
      :key="payment_token"
      :public-key="public_key"
      :form-token="payment_token"
      @onSubmit="onPaymentSubmit"
      @onError="onPaymentError"
    />
    <div
      v-if="alert_color"
      class="wrap"
    >
      <SweetAlert :color="alert_color" />
    </div>
  </div>
</template>

<script>
import SweetAlert from "@/components/SweetAlert";
import Payment from "@/components/forms/order/payment.vue";

const paymentWidget = {
  components: { SweetAlert, Payment },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payment_token: null,
      payment: null,
      loading: false,
      alert_color: null,
      payment_finish: false
    };
  },
  mounted() {},
  created() {
    this.loading = true;
    this.getToken();
  },
  methods: {
    getToken() {
      this.$axios
        .post("ecommerce/orders/" + this.options.orderId + "/payments/token", {
          amount: this.options.amount
        })
        .then(res => {
          // add style
          let payzenStyle = document.createElement("link");
          payzenStyle.setAttribute("rel", "stylesheet");
          payzenStyle.setAttribute("href", process.env.VUE_APP_PAYZEN_CSS);
          document.querySelector("head").appendChild(payzenStyle);

          // add script
          let payzenScript = document.createElement("script");
          payzenScript.setAttribute("src", process.env.VUE_APP_PAYZEN_JS);
          document.querySelector("head").appendChild(payzenScript);

          this.payment_token = res.data.token;
          this.public_key = res.data.public_key;
          this.payment = res.data.payment;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    },
    onPaymentSubmit() {
      this.alert_color = "success";
      setTimeout(() => {
        this.$root.closeDrawer(1);
        this.payment["status"] = "OK";
        this.$bus.$emit("after-online-payment", this.payment);
      }, 1000);
    },
    onPaymentError(error) {
      this.alert_color = "error";
      if (error === "ACQ_001") {
        setTimeout(() => {
          this.$root.closeDrawer(1);
          this.payment["status"] = "KO";
          this.payment["comment"] = null;
          this.$bus.$emit("after-online-payment", this.payment);
        }, 1000);
      }
    }
  }
};

export default paymentWidget;
</script>
