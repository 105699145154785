<template>
  <ul
    id="stores-search"
    no-gutters
    class="stores-list nowrap"
  >
    <li
      id="search-form"
      class="search"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit }"
        class="is-block"
        tag="div"
      >
        <span class="label">{{ $t("search_by_zip_city") }}</span>
        <form
          autocomplete="off"
          class="mt-1 columns is-mobile is-multiline"
        >
          <div class="column is-4">
            <b-input-validation
              v-model="search.zip"
              rules="required|min:2"
              maxlength="15"
              :label="$t('zip')"
              vid="zip"
              size="is-small"
              :placeholder="placeholders.address.zip"
            />
          </div>
          <div class="column is-5">
            <b-input-validation
              v-model="search.city"
              rules="required"
              :label="$t('city')"
              vid="city"
              maxlength="35"
              size="is-small"
              :placeholder="placeholders.address.city"
            />
          </div>
          <div class="column is-3">
            <b-button
              :loading="search_loading"
              expanded
              :disabled="search_loading"
              size="is-small"
              type="is-primary"
              @click="handleSubmit(submitChronoRelais)"
            >
              <b-icon
                icon="magnify"
                size="is-small"
              />
              <span>
                {{ $t("search_verb") }}
              </span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </li>

    <li v-if="stores.length === 0 && search_sent">
      {{ $t("no_result_found") }}
    </li>

    <StoreInfo
      v-for="store in stores"
      v-else
      :id="store.ref"
      :key="store.ref"
      :order-id="orderId"
      v-bind:class="selectedStore === store.ref ? 'active' : null"
      :store="store"
      :selected-store="selectedStore"
    />
  </ul>
</template>

<script>
import StoreInfo from "./StoreInfo";
import { mapGetters } from "vuex";
export default {
  components: { StoreInfo },
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isSelectedLocationEdited: false,
      editedLocation: null,
      ignoreScrollToSelectedStore: false,
      hoveredOnStore: null,
      search: { zip: "", city: "" },
      position: { lat: 0, lng: 0 },
      search_loading: false,
      search_sent: false
    };
  },
  computed: {
    ...mapGetters({
      stores: "getStores",
      selectedLocation: "getSelectedLocation",
      selectedStore: "getSelectedStore"
    }),
    selectedLocation: {
      get() {
        const location = this.$store.state.selectedLocation;
        return location.city + ", " + location.state;
      },
      set(newValue) {
        // store the edited location in temp variable before confirming and commit to vuex store
        this.editedLocation = newValue;
      }
    },
    selectedStore: {
      get() {
        return this.$store.state.selectedStore;
      }
      //   set(value) {
      //     this.$store.dispatch("updateSelectedStore", value);
      //   }
    },
    storesCount() {
      return this.stores.length;
    }
  },
  watch: {
    selectedStore(newValue) {
      // need to wait until the selected store class changes
      if (this.ignoreScrollToSelectedStore) {
        this.ignoreScrollToSelectedStore = false;
      } else {
        // triger the auto scroll only if the selection is triggered from outside the list
        setTimeout(() => {
          this.scrollToSelectedStore(newValue);
        }, 50);
      }
    }
  },
  mounted() {
    if (this.shipping_address) {
      this.search.zip = this.shipping_address.zip;
      this.search.city = this.shipping_address.city;
    }
  },
  destroyed() {
    this.$bus.$off("gotoStoreEvent");
  },
  beforeDestroy() {
    this.$store.commit("SET_STORES", []);
  },
  methods: {
    submitChronoRelais() {
      this.search_loading = true;

      this.updatePickupPoints({
        zip: this.search.zip,
        city: this.search.city
      });
    },
    updatePickupPoints(params) {
      this.$axios
        .get("ecommerce/orders/" + this.orderId + "/pickup", {
          params: params
        })
        .then(res => {
          this.$store.commit("SET_STORES", res.data);
          this.$store.commit("SET_SELECTED_LOCATION", res.data[0]);
          this.search_sent = true;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.search_loading = false));
    }
  }
};
</script>
