<template>
  <div
    v-if="!loading && $can('read', 'orders')"
    class="wrap"
  >
    <p class="title is-size-4-mobile mb-5">
      {{ $tc("order", 1) }}
      <span class="has-text-grey-light"> #{{ mpId ? mpId : options.order_id }} </span>
    </p>
    <Form
      v-if="details && payments && summary && $can('read', 'orders')"
      :index="options.index"
      :order-id="options.order_id"
      :options="{ details, payments, summary }"
      :incoterms="incoterms"
      :mergeable_with="mergeable_with"
      :mp-id="options.mp_id"
    />
    <Details
      v-if="addresses && items && summary && $can('read', 'orders')"
      :options="{
        addresses: addresses,
        orderId: options.order_id,
        userId: options.user_id,
        items: items,
        summary: summary,
        carrier: details.carrier,
        site_id: site.id,
        warehouse_id: warehouse.id,
        lang_iso: lang.iso
      }"
      :incoterm="details.incoterm"
      :notes="details.notes"
      :editable="editable"
      :editable-pricing="editablePricing"
      :count="count"
      :warehouses="options.warehouses"
    />
    <b-modal
      v-model="showShippedModal"
      :width="520"
      :height="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("shipping_info") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-mobile">
          <b-input-validation
            v-model="tracking_num" 
            class="column is-12"                
            :label="`${$t('tracking_number')} (${$t('optional')})`"                
            :placeholder="placeholders.order.tracking_num"
          />

          <b-switch
            v-model="notify_customer" 
            class="column is-12 pt-0"
          >
            {{
              $t("notify_customer")
            }}
          </b-switch>
       
      
          <div class="column is-12">
            <b-button
              class="mr-4"
              type="is-success"
              tag="input"
              :value="$t('validate')"
              :loading="loading"
              :disabled="loading"
              @click="saveShippedStatusInfo()"
            />
        
            <b-button            
              @click="showShippedModal = false"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </div>
  
  <b-loading
    v-else
    v-model="loading"
    :is-full-page="false"
  />
</template>

<script>
import Form from "@/components/forms/order/Form";
import Details from "@/components/forms/order/Details";

export default {
  components: {
    Form,
    Details
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      incoterms: [],
      order: {},
      addresses: null,
      details: null,
      items: [],
      summary: null,
      payments: [],
      site: {},
      lang: {},
      warehouse: {},
      show: false,
      editable: false,
      editablePricing: true,
      mpId: this.options.mp_id,
      mergeable_with: [],
      showShippedModal: false,
      tracking_num: null,
      notify_customer:false,
      count: {},
    };
  },
  computed: {
    rerender() {
      return JSON.stringify(this.editable) + this.options.order_id;
    }
  },
  created() {
    if (this.options.order_id !== null && this.$can("read", "orders")) {
      this.getOrder(this.options.order_id);
    }
  },
  mounted() {
    this.$bus.$on("update-order-options", params => {
      this.$bus.$emit("update-list", params);
      let status =
        params.details && params.details.statuses.find(s => s.selected);
      if (status) this.changeEditable(status.id);
      if (params.items) this.items = params.items;
      if (params.summary) this.summary = params.summary;
      if (params.details) this.details = params.details;
      if (params.count) this.count = params.count;
    });
    this.$bus.$on("update-order-note-icoterm", obj => {
      this.details.notes = obj.notes;
      this.details.incoterm = obj.incoterm;
    });
    this.$bus.$on("open-shipped-modal", () => {
      this.showShippedModal = true;
    });
  },
  destroyed() {
    this.$bus.$off("update-order-options");
    this.$bus.$off("update-order-note-icoterm");
  },
  methods: {
    getOrder() {
      this.loading = true;

      this.$axios
        .get("ecommerce/orders/" + this.options.order_id)
        .then(res => {
          this.addresses = res.data.addresses;
          this.items = res.data.items;
          this.summary = res.data.summary;
          this.details = res.data.details;
          this.payments = res.data.payments;
          let status = this.details.statuses.filter(s => s.selected === true);
          this.site = res.data.site;
          this.lang = res.data.lang;
          this.warehouse = res.data.warehouse;
          this.incoterms = res.data.incoterms;
          this.mergeable_with= res.data.mergeable_with;
          let trackingNums = res.data.details.tracking.map(a => a.number);         
          this.tracking_num = trackingNums.join(',');
          this.count = res.data.count;
          this.changeEditable(status[0]["id"]);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    },
    changeEditable(status_id) {
      this.editablePricing = this.mpId ? false : true ; 
      if ((status_id == 2 || status_id == 3) && !this.mpId) this.editable = true;
      else this.editable = false;
    },
    saveShippedStatusInfo() {
      this.loading = true;
      let data = {
        tracking_numbers: this.tracking_num.split(','),
        notify_customer: this.notify_customer,
      };
      this.$axios
        .patch("ecommerce/orders/" + this.options.order_id, data)
        .then(res => {
          this.details = res.data.details;
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch(e => this.clientError(e))
        .finally(() => {
          this.loading = false;
          this.showShippedModal = false;
        });
    }
  }
};
</script>

