<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-tablet is-6-mobile">
          <b-input-validation
            v-model="myAddress.fname"
            rules="required|min:2|max:30"
            :label="$t('fname')"
            vid="fname"
            :placeholder="placeholders.user.fname"
          />
        </div>
        <div class="column is-6-tablet is-6-mobile">
          <b-input-validation
            v-model="myAddress.lname"
            rules="required|min:2|max:30"
            :label="$t('lname')"
            vid="lname"
            :placeholder="placeholders.user.lname"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myAddress.company"
            :label="`${$t('company')} (${$t('optional')})`"
            :placeholder="placeholders.company.name"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-field addons>
            <p class="control">
              <b-autocomplete-validation
                v-model="myAddress.phone.code"
                fit-content
                rules="required"
                :label="$t('country_code')"
                :data="countries"
                :placeholder="placeholders.user.phone.code"
                vid="country_code"
                item-text="code"
                item-text2="country"
                field="code"
                input-type="phone"
              />
            </p>
            <p class="control is-expanded">
              <b-input-validation
                v-model="myAddress.phone.number"
                expanded
                rules="required"
                :label="$t('phone')"
                vid="phone"
                input-type="tel"
                :placeholder="placeholders.user.phone.number"
              />
            </p>
          </b-field>
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myAddress.address1"
            rules="required|max:35"
            :label="$t('address1')"
            vid="address1"
            :placeholder="placeholders.address.line1"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myAddress.address2"
            :label="`${$t('address2')} (${$t('optional')})`"
            rules="max:35"
            vid="address2"
            :placeholder="placeholders.address.line2"
          />
        </div>
        <div class="column is-2-tablet is-4-mobile pr-0">
          <b-input-validation
            v-model="myAddress.zip"
            rules="required|min:2|max:15"
            :label="$t('zip')"
            vid="zip"
            :placeholder="placeholders.address.zip"
          />
        </div>
        <div class="column is-4-tablet is-8-mobile">
          <b-input-validation
            v-model="myAddress.city"
            rules="required|max:35"
            :label="$t('city')"
            vid="city"
            :placeholder="placeholders.address.city"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-autocomplete-validation
            v-model="myAddress.country.iso"
            rules="required"
            :expanded="true"
            :label="$tc('country', 1)"
            :data="countries"
            :placeholder="placeholders.user.phone.country"
            vid="country"
            item-text="country"
            field="iso"
            type="country"
          />
        </div>
        <div class="column is-12-tablet is-12-mobile pt-0">
          <b-switch v-model="myAddress.main">
            {{ $t("main_address") }}
          </b-switch>
        </div>
        <div class="column">
          <b-button
            type="is-primary"
            :disabled="update_loading"
            :loading="update_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: -1,
    },
    userId: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      default: null,
    },
    address: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    from: {
      type: String,
      default: "user-page",
    },
  },
  data() {
    return {
      update_loading: false,
      myAddress: {},
    };
  },
  computed: {
    method() {
      if (this.index !== -1) return "put";
      else return "post";
    },
  },
  created() {
    this.myAddress = JSON.parse(JSON.stringify(this.address));
    if (this.index === -1) this.myAddress.main = false;
  },
  methods: {
    save() {
      let address_id = this.myAddress.id ? this.myAddress.id : "";
      let self = this;
      let url;
      if (this.from == "orders-page")
        url = `ecommerce/orders/${this.orderId}/addresses/${address_id}`;
      else url = `users/${self.userId}/addresses/${address_id}`;
      self.update_loading = true;
      this.$axios({
        method: self.method,
        url: url,
        data: self.myAddress,
      })
        .then((res) => {
          self.$bus.$emit("update-user-address", {
            index: self.index,
            address: res.data,
          });
        })
        .catch((e) => this.clientError(e))
        .finally(() => (self.update_loading = false));
    },
  },
};
</script>

