<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ options.company ? $t("edit_noun") : $t("company") }}
    </p>
    <CompanyForm :options="options" />
  </div>
</template>

<script>
import CompanyForm from "@/components/forms/Company";

export default {
  components: {
    CompanyForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    index() {
      return this.options.index;
    }
  }
};
</script>
