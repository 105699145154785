<template>
  <div
    class="columns is-2 is-multiline is-mobile"
  >
    <template v-for="(category, index) in formatedItems">
      <b-autocomplete-validation
        :key="`category-${category.id}-${index}-product-${category.product_permalink}`"
        v-model="category.product_permalink"
        class="column is-6-tablet is-12-mobile"
        :label="`${$tc('product', 1)} ${index + 1}, ${$tc('category', 1)} ${
          category.label
        }`"
        :placeholder="placeholders.product.name"
        :data="category.products"
        :vid="`product-${category.id}-${index}`"
        rules="required"
        size="is-small"
        item-text="name"
        field="permalink"
        width="100%"
        fit-content
        @change="(permalink) => selectSizes(permalink, index)"
      >
        <template #item="{ props }">
          <span>{{ props.option.name }}</span>
        </template>
      </b-autocomplete-validation>

      <b-autocomplete-validation
        :key="`category-${category.id}-${index}-size-${category.product_permalink}`"
        v-model="category.size_id"
        class="column is-3-tablet is-12-mobile"
        :label="$tc('size', 1)"
        :disabled="!category.product_permalink"
        :placeholder="placeholders.product.name"
        :data="category.sizes || []"
        :vid="`size-${category.id}-${index}`"
        rules="required"
        size="is-small"
        item-text="size"
        field="size"
        width="100%"
        fit-content
        @change="
          (size) => selectAttributes(size, category.product_permalink, index)
        "
      >
        <template #item="{ props }">
          <span>{{ props.option.size }}</span>
        </template>
      </b-autocomplete-validation>

      <SizeAutocomplete
        v-if="canDisplaySku(category)"
        :key="`category-${category.id}-${index}-sku-${category.product_permalink}-${category.size_id}`"
        v-model="category.sku"
        class="column is-3-tablet is-12-mobile"
        :label="getSkuLabel(category)"
        :disabled="!category.product_permalink || !category.size_id"
        :data="category.items || []"
        :vid="`attribute-${category.id}-${index}`"
        rules="required"
        size="is-small"
        :item-text="
          category.items && category.items[0].attributes.flavors
            ? 'attributes.flavors.value'
            : 'attributes.colors.value'
        "
        item-id="id"
        :item-search="
          category.items && category.items[0].attributes.flavors
            ? 'attributes.flavors.value'
            : 'attributes.colors.value'
        "
        fit-content
        width="100%"
      >
        <template #default="{ props }">
          <span>{{
            (props.option.attributes.flavors &&
              props.option.attributes.flavors.value) ||
              (props.option.attributes.colors &&
                props.option.attributes.colors.value)
          }}</span>
        </template>
      </SizeAutocomplete>
    </template>
  </div>
</template>

<script>
import SizeAutocomplete from "@/components/forms/elements/SizeAutocomplete.vue";

export default {
  components: {
    SizeAutocomplete,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    pack: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatedItems: [],
      add_pack_loading: false,
    };
  },
  computed: {
    totalSellingItems() {
      return this.getTotal("selling");
    },
    totalBaseItems() {
      return this.getTotal("base");
    },
  },
  watch: {
    formatedItems: {
      handler(newVal) {
        this.$emit('change', newVal);
      },
      deep: true
    }
  },
  created() {
    this.formatedItems = this.getFormatedItems();
    this.setSelectedProducts();
  },
  methods: {
    getTotal(prop) {
      let hideTotal = this.formatedItems.reduce((result, item) => {
        if (!item.id || !item.size_id || !item.sku) result = true;
        return result;
      }, false);
      if (hideTotal) return 0;

      return this.formatedItems.reduce((sum, item) => {
        if (item.id && item.size_id && item.sku) {
          let selected = item.items.find((obj) => obj.id == item.sku);
          let price = selected.price[prop].value;
          sum += price || 0;
          return sum;
        } else {
          hideTotal = true;
        }
      }, 0);
    },
    getSkuLabel(category) {
      if (category.items) {
        if (
          category.items[0] &&
          category.items[0].attributes &&
          category.items[0].attributes.flavors
        )
          return category.items[0].attributes.flavors.label;
        else if (
          category.items[0] &&
          category.items[0].attributes &&
          category.items[0].attributes.colors
        )
          return category.items[0].attributes.colors.label;
      } else {
        return (
          (category.products[0].sizes[0].items[0].attributes.flavors &&
            category.products[0].sizes[0].items[0].attributes.flavors.label) ||
          (category.products[0].sizes[0].items[0].attributes.colors &&
            category.products[0].sizes[0].items[0].attributes.colors.label)
        );
      }
    },
    canDisplaySku(category) {
      if (!category.product_permalink) return true;
      else {
        if (category.items && category.items.length) {
          return category.items[0].attributes.flavors
            ? category.items[0].attributes.flavors.value
              ? true
              : false
            : category.items[0].attributes.colors.value
            ? true
            : false;
        }

        if (category.sizes && category.sizes.length) {
          return category.sizes[0].items[0].attributes.flavors
            ? category.sizes[0].items[0].attributes.flavors.value
              ? true
              : false
            : category.sizes[0].items[0].attributes.colors.value
            ? true
            : false;
        }
      }
      return false;
    },
    getFormatedItems() {
      return this.categories.reduce((arr, cat) => {
        for (let i = 0; i < cat.quantity; i++) {
          arr.push({
            ...cat,
            product_permalink: null,
            size_id: null,
            sku: null,
          });
        }
        return arr;
      }, []);
    },
    setSelectedProducts() {
      if (this.pack.refs) {
        for (let index = 0; index < this.pack.refs.length; index++) {
          const prd = this.pack.refs[index];

          // search for product in  categories[index].products
          let product = this.formatedItems[index].products.find(
            (p) => p.permalink == prd.permalink
          );

          if (product) {
            this.formatedItems[index].product_permalink = product.permalink;
            // select size
            this.selectSizes(product.permalink, index);

            let size = product.sizes.find((s) => s.size == prd.size);
            if (size) {
              this.formatedItems[index].size_id = size.size;

              this.selectAttributes(size.size, product.permalink, index);
            }

            if (
              this.formatedItems[index].items &&
              this.formatedItems[index].items.length > 1
            ) {
              let item = this.formatedItems[index].items.find(
                (i) => i.id == prd.id
              );

              if (item) {
                this.formatedItems[index].sku = item.id;
              }
            }
          }
        }
      }
    },
    selectSizes(permalink, index) {
      if (permalink) {
        let category = this.formatedItems[index];
        let product = category.products.find((p) => p.permalink == permalink);

        this.formatedItems[index].sizes = product.sizes;

        this.formatedItems[index].size_id = null;
        this.formatedItems[index].sku = null;

        if (product.sizes.length == 1) {
          this.formatedItems[index].size_id = product.sizes[0].size;
          this.selectAttributes(product.sizes[0].size, permalink, index);
        }
      } else {
        this.formatedItems[index].sizes = [];
        this.formatedItems[index].size_id = null;
        this.formatedItems[index].sku = null;
      }
    },
    selectAttributes(size, permalink, index) {
      if (size) {
        let category = this.formatedItems[index];
        let product = category.products.find((p) => p.permalink == permalink);
        let s = product.sizes.find((sze) => sze.size == size);

        this.formatedItems[index].items = s.items;
        this.formatedItems[index].sku = null;

        if (s.items.length == 1) {
          this.formatedItems[index].sku = s.items[0].id;
        }
      }
    },
  },
};
</script>
