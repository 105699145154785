<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <SlideForm
      :key="options.key"
      :index="options.index"
      :sites="options.sites"
      :warehouses="options.warehouses"
      :from="options.from"
    />
  </div>
</template>

<script>
import SlideForm from "@/components/forms/Slide.vue";
export default {
  components: { SlideForm },
  props: {
    options: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      title: null
    };
  },
  created() {
    this.title = this.$tc("slide", 2);
  }
};
</script>
