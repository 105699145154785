<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-3-tablet is-5-mobile pr-0">
          <b-select-validation
            v-model="myUser.site_id"
            rules="required"
            :disabled="index != -1"
            :label="$t('site')"
          >
            <template>
              <option
                v-for="opt in options.sites"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-3-tablet is-7-mobile">
          <b-select-validation
            v-model="myUser.tld_id"
            rules="required"
            :label="$t('tld')"
            :disabled="!showTld"
          >
            <template>
              <option
                v-for="opt in tlds"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.country.label
                }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div
          class="column"
          :class="
            showSalesmen
              ? 'is-3-tablet is-5-mobile pr-0'
              : 'is-6-tablet is-12-mobile'
          "
        >
          <b-select-validation
            v-model="myUser.lang_iso"
            rules="required"
            :label="$tc('language', 1)"
          >
            <template>
              <option
                v-for="opt in languages"
                :key="opt.iso"
                :value="opt.iso"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div
          v-if="showSalesmen"
          class="column is-3-tablet is-7-mobile"
        >
          <b-select-validation
            v-model="myUser.salesman_id"
            :label="$tc('salesman', 1)"
            :disabled="!$can('update', 'users.business.salesman')"
            rules="required"
          >
            <template>
              <option
                v-for="opt in options.salesmen"
                :key="opt.id"
                :value="opt.id"
              >
                {{ `${opt.fname} ${opt.lname}` }}
              </option>
            </template>
          </b-select-validation>
        </div>
        <div class="column is-3-tablet is-12-mobile pr-0">
          <b-input-validation
            v-model="myUser.fname"
            rules="required|min:2"
            maxlength="30"
            :label="$t('fname')"
            vid="fname"
            :placeholder="placeholders.user.fname"
          />
        </div>
        <div class="column is-3-tablet is-12-mobile">
          <b-input-validation
            v-model="myUser.lname"
            rules="required|min:2"
            maxlength="30"
            :label="$t('lname')"
            vid="lname"
            :placeholder="placeholders.user.lname"
            @blur="addSignature"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myUser.signature"
            rules="required"
            :label="$t('signature')"
            vid="signature"
            :placeholder="placeholders.user.signature"
            @blur="addSignature"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            :key="myUser.site_id"
            v-model="myUser.email"
            :rules="emailValidation"
            :label="$t('email')"
            vid="email"
            validation-mode="lazy"
            input-type="email"
            :placeholder="placeholders.user.email"
            @typing="emailChanged = true"
          />
        </div>
        <div class="column is-6-tablet is-12-mobile">
          <b-field addons>
            <p class="control">
              <b-autocomplete-validation
                v-model="myUser.phone.code"
                rules="required"
                fit-content
                :expanded="true"
                :label="$t('country_code')"
                :data="options.countries"
                :placeholder="placeholders.user.phone.code"
                vid="country_code"
                item-text="code"
                item-text2="country"
                field="code"
                type="phone"
              />
            </p>
            <p class="control is-expanded">
              <b-input-validation
                v-model="myUser.phone.number"
                rules="required"
                :label="$t('phone')"
                expanded
                vid="phone"
                input-type="tel"
                :placeholder="placeholders.user.phone.number"
              />
            </p>
          </b-field>
        </div>
        <div class="column is-12 pt-0">
          <b-switch v-model="myUser.offers">
            {{
              $t("receive_our_promotions")
            }}
          </b-switch>
        </div>
        <div class="column">
          <b-button
            type="is-primary"
            :disabled="user_loading"
            :loading="user_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user_loading: false,
      emailChanged: false,
      languages: [],
      tlds: [],
      type: "b2c",
      myUser: {
        fname: "",
        lname: "",
        email: "",
        phone: {
          code: "",
          number: ""
        },
        site_id: "",
        tld_id: "",
        lang_iso: "",
        offers: false
      }
    };
  },
  computed: {
    index() {
      return this.options.index;
    },
    method() {
      if (this.index !== -1) return "put";
      else return "post";
    },
    showTld() {
      if (this.index === -1) return true;
      else if (this.index !== -1 && this.$can("update", "users.tld"))
        return true;
      else return false;
    },
    showSalesmen() {
      return this.index !== -1 && this.type === "b2b";
    },
    emailValidation() {
      // use id 0 to check for email if we're in creation
      let user_id = this.myUser.id ? this.myUser.id : 0;
      return `required|email|${
        this.emailChanged && this.myUser.site_id ? `valid_email:${user_id}` : ""
      }`;
    }
  },
  watch: {
    // watch for the selected site to show it's Tlds ans languages in select
    "myUser.site_id": function(new_value) {
      let site = this.options.sites.filter(s => s.id === new_value);
      this.languages = site[0]["languages"];
      this.tlds = site[0]["tlds"];
      this.$axios.defaults.headers.common["X-SID"] = new_value;
      // this.$refs.observer.validate();
    }
  },
  created() {
    if (this.$can("create", "users") || this.$can("update", "users")) {
      // load user for edit
      if (this.index !== -1) {
        this.myUser = JSON.parse(JSON.stringify(this.options.user));
        this.myUser.site_id = this.myUser.site.id;
        this.myUser.tld_id = this.myUser.tld.id;
        let site = this.options.sites.find(s => s.id === this.myUser.site.id);
        this.tlds = site.tlds;
        this.type = site.type;
        if (
          this.myUser.phone &&
          typeof this.myUser.phone == "string" &&
          this.myUser.phone != "Removed"
        ) {
          let phone = this.myUser.phone.split(" ");
          this.myUser.phone = {
            code: phone[0],
            number: phone[1]
          };
        } else if (typeof this.myUser.phone != "object" || !this.myUser.phone) {
          this.myUser.phone = {
            code: "",
            number: ""
          };
        }
        this.myUser.salesman_id = this.myUser.salesman
          ? this.myUser.salesman.id
          : null;
        this.myUser["lang_iso"] = this.myUser.lang_iso
          ? this.myUser.lang_iso
          : this.myUser.language.iso
          ? this.myUser.language.iso
          : null;
        // delete this.myUser.salesman;
        delete this.myUser.language;
      }
    }
  },
  methods: {
    addSignature() {
      if (this.myUser.fname && this.myUser.lname && !this.myUser.signature);
      this.myUser.signature = `${this.myUser.fname} ${this.myUser.lname.charAt(
        0
      )}.`;
    },
    save() {
      if (this.$can("create", "users") || this.$can("update", "users")) {
        this.user_loading = true;
        let copy_user = JSON.parse(JSON.stringify(this.myUser));
        delete copy_user.salesman;
        let user_id = this.myUser.id ? "/" + this.myUser.id : "";
        let self = this;

        this.$axios({
          method: self.method,
          url: "users" + user_id,
          data: copy_user
        })
          .then(res => {
            this.closeDrawer("main");
            if (self.method === "put") { 
              this.$bus.$emit("update-user", res.data);
              this.$root.notify(this.$t("successfully_updated"), "is-success");
            }
            else this.$bus.$emit("new-user", res.data);
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.user_loading = false));
      }
    }
  }
};
</script>

