<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(updatePassword)"
    >
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-input-validation
            v-model="password_old"
            rules="valid_password|required"
            validation-mode="lazy"
            :label="$t('old_password')"
            vid="old_password"
            input-type="password"
            :placeholder="placeholders.user.password"
          />
        </div>
        <div class="column is-12">
          <b-input-validation
            v-model="password"
            rules="required|min:6"
            :label="$t('new_password')"
            vid="new_password"
            input-type="password"
            :placeholder="placeholders.user.password"
          />
        </div>
        <div class="column is-12">
          <b-input-validation
            v-model="password_confirmation"
            rules="required|confirmed:new_password"
            :label="$t('confirm_password')"
            vid="confirm_password"
            input-type="password"
            :placeholder="placeholders.user.password"
          />
        </div>
        <div class="column">
          <b-button
            type="is-primary"
            :disabled="password_loading"
            :loading="password_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  data() {
    return {
      password_old: "",
      password: "",
      password_confirmation: "",
      password_loading: false
    };
  },
  methods: {
    updatePassword() {
      this.password_loading = true;
      this.$axios
        .patch("me/password", {
          password_old: this.password_old,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
        .then(() => {
          //   this.$root.notify(this.$t("successfully_saved"), "success");
          this.$root.closeDrawer();
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.password_loading = false));
    }
  }
};
</script>

