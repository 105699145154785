<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(saveDeal)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-tablet is-12-mobile">
          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            vid="site"
            name="site"
            rules="required"
          >
            <b-field
              :label="$t('site')"
              :type="{
                'is-default': index !== -1 || isShow,
                'is-danger': errors[0],
              }"
              :message="errors"
            >
              <b-taginput
                ref="taginput"
                v-model="mySites"
                rules="required"
                :data="sitesitems"
                :placeholder="
                  mySites && mySites.length == 0 ? $t('choose') : ''
                "
                field="label"
                :disabled="index !== -1 || isShow"
                :autocomplete="true"
                open-on-focus
                ellipsis
                @add="
                  obj => (sitesitems = sitesitems.filter(s => s.id != obj.id))
                "
                @typing="filterSites"
                @remove="obj => sitesitems.push(obj)"
              >
                <template slot-scope="props">
                  {{ props.option.label }}
                </template>
                <template
                  slot="selected"
                  slot-scope="props"
                >
                  <b-tag
                    v-for="(tag, i) in props.tags"
                    :key="i"
                    type="is-default"
                    :tabstop="false"
                    closable
                    @close="$refs.taginput.removeTag(i, $event)"
                  >
                    {{ tag.label }}
                  </b-tag>
                </template>
              </b-taginput>
            </b-field>
          </ValidationProvider>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myDeal.warehouse_id"
            rules="required"
            vid="warehouse"
            :disabled="index !== -1 || isShow"
            :label="$tc('warehouse', 1)"
          >
            <template>
              <option
                v-for="opt in warehouses"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.country.country }} - {{ opt.currency.code }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myDeal.type"
            rules="required"
            vid="type"
            :disabled="index !== -1 || isShow"
            :label="$t('type')"
          >
            <template>
              <option
                v-for="opt in types"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.label
                }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div
          v-if="myDeal.type === 'gift' || myDeal.type == 'global'"
          class="column is-6-tablet is-12-mobile"
        >
          <SearchProduct
            :disabled="isShow || myDeal.type == 'global'"
            :label="$tc('product', 1)"
            type="size"
            :autofocus="!myDeal.prod_id"
            :selected-prod="
              `${deal.product ? deal.product.label : ''}${
                deal.size ? ' - ' + deal.size.label : ''
              }`
            "
            :sites-ids="mySitesMapped"
            :warehouse-id="myDeal.warehouse_id"
            @selectItem="selectProduct"
          />
        </div>

        <div
          v-else
          class="column is-6-tablet is-12-mobile"
        >
          <b-autocomplete-validation
            v-model="myDeal.brand_id"
            rules="required"
            :expanded="true"
            :label="$tc('brand', 1)"
            :data="brands"
            :placeholder="$t('choose')"
            vid="brand_id"
            :disabled="index !== -1 || isShow"
            item-text="label"
            field="id"
          />
        </div>

        <div class="column is-3-tablet is-12-mobile">
          <b-datepicker-validation
            v-model="myDeal.starts_at"
            :label="$t('starts_at')"
            rules="required"
            vid="starts_at"
            :disabled="isShow"
            :min-date="new Date()"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
          />
        </div>

        <div class="column is-3-tablet is-12-mobile">
          <b-datepicker-validation
            v-model="myDeal.ends_at"
            :label="$t('expire_at')"
            rules="required"
            vid="expire_at"
            :min-date="myDeal.starts_at"
            :disabled="isShow"
            :placeholder="placeholders.date.time"
            format="YYYY-MM-DD HH:mm:ss"
            time-picker
          />
        </div>

        <div class="column is-2-tablet is-6-mobile">
          <b-input-validation
            v-model="myDeal.trigger"
            :rules="myDeal.type !== 'cashback' && myDeal.type !== 'global' ? 'required' : ''"
            :disabled="trigger_disabled || isShow"
            :suffix="myDeal.type === 'brand' || myDeal.type === 'global' ? currency : null"
            :label="$t('trigger')"
            vid="trigger"
            :placeholder="placeholders.number['2']"
            input-type="number"
          />
        </div>

        <div class="column is-2-tablet is-6-mobile">
          <b-input-validation
            v-model="myDeal.offer"
            rules="required"
            :disabled="isShow"
            :suffix="offer_suffix"
            :label="$t('offer')"
            vid="offer"
            :placeholder="placeholders.number['1']"
            input-type="number"
          />
        </div>

        <div class="column is-12-mobile is-2-tablet mt-2">
          <b-switch
            v-model="myDeal.combo"
            :disabled="isShow"
          >
            {{
              $t("combo")
            }}
          </b-switch>
        </div>

        <template v-if="myDeal.type !== 'cashback'">
          <div class="column is-12">
            <span
              class="is-size-5"
            ><b>{{ $t("offered_products") }}</b></span>
          </div>

          <template v-for="(o, i) in offered_products">
            <div
              :key="`sku_prod_${o.prod_ref_id}`"
              class="column is-7-tablet is-8-mobile"
            >
              <SearchProduct
                :key="`sku_${i}`"
                :disabled="isShow"
                :autofocus="!o.prod_ref_id && i != 0"
                :label="$tc('sku_or_product')"
                :sites-ids="mySitesMapped"
                :selected-prod="o['label'] || o['prod_ref_id']"
                :exclude="
                  offered_products.reduce(
                    (arr, obj) => [...arr, obj.prod_ref_id],
                    []
                  )
                "
                :warehouse-id="myDeal.warehouse_id"
                @selectItem="
                  product => {
                    offered_products[i]['prod_ref_id'] = product.id;
                    offered_products[i]['label'] = product.label;
                    updateSku(product, i);
                  }
                "
              />
            </div>

            <div
              :key="`sku_threshold_${o.prod_ref_id}`"
              class="column is-4-tablet is-2-mobile px-0"
            >
              <b-input-validation
                v-model="offered_products[i]['threshold']"
                :disabled="isShow"
                :label="$t('threshold') + ' (' + $t('optional') + ')'"
                :suffix="currency"
                :placeholder="placeholders.price.int"
                input-type="number"
              />
            </div>

            <div
              :key="`sku_delete_${o.prod_ref_id}`"
              class="column is-1-tablet is-2-mobile"
            >
              <b-button
                v-if="
                  offered_products && i < offered_products.length - 1 && !isShow
                "
                @click="deleteOfferedProducts(i)"
              >
                <b-icon
                  icon="delete"
                  type="is-danger"
                  custom-size="mdi-18px"
                />
              </b-button>
            </div>
          </template>
        </template>

        <div class="column">
          <b-button
            type="is-primary"
            :disabled="save_deal_loading || isShow"
            :loading="save_deal_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import SearchProduct from "@/components/forms/elements/searchProduct.vue";
import { ValidationProvider } from "vee-validate";

import moment from "@/plugins/moment";

export default {
  components: {
    SearchProduct,
    ValidationProvider
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    deal: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    brands: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sitesitems: this.sites,
      showProductSearch: true,
      save_deal_loading: false,
      types: [
        { id: "global", label: this.$i18n.t("global") },
        { id: "gift", label: this.$i18n.tc("gift", 1) },
        { id: "brand", label: this.$i18n.tc("brand", 1) },
        { id: "cashback", label: this.$i18n.t("cashback") }
      ],
      offered_products: [
        {
          prod_ref_id: null,
          threshold: null,
          products: [],
          search: null,
          label: ""
        }
      ],
      with_size: false,
      size_id: null,

      // prod_id
      products: [],
      product: null,
      product_loading: false,

      // prod_ref_id
      prod_ref_id: null,
      prod_ref_id_search: null,

      completed: false,
      trigger_disabled: false,
      offer_suffix: null,

      // read, create, update
      // action: "create",

      myDeal: {
        end_at: "2019-01-01 12:00",
        sites: [],
        warehouse_id: null
      },
      currency: null,
      mySites: []
    };
  },
  computed: {
    mySitesMapped() {
      return this.mySites.reduce((arr, s) => [...arr, s.id], []);
    },
    startsAtComputed() {
      return moment(this.myDeal.starts_at).format("YYYY-MM-DD HH:mm:ss");
    },
    endsAtComputed() {
      return moment(this.myDeal.ends_at).format("YYYY-MM-DD HH:mm:ss");
    },
    method() {
      if (this.index === -1) return "post";
      else return "put";
    },
    isShow() {
      return this.action == "read";
    }
  },
  watch: {
    "myDeal.type": function(val) {
      this.showProductSearch = true;
      if (val === "cashback") {
        this.deal.trigger = null;
        this.trigger_disabled = true;
        this.offer_suffix = "%";
      } else {
        this.trigger_disabled = false;
        this.offer_suffix = null;
      }
    },
    "myDeal.warehouse_id": function(val) {
      let warehouse = this.warehouses.filter(w => w.id === val);
      if (warehouse.length > 0) this.currency = warehouse[0].currency.symbol;
    }
  },
  created() {
    if (this.deal.site_id)
      this.mySites[0] = this.sites.find(s => s.id == this.deal.site_id);
    else this.mySites = [];
    this.myDeal.brand_id = this.deal.brand ? this.deal.brand.id : null;
  },
  mounted() {
    if (this.index !== -1) {
      this.myDeal.id = this.deal.id;
      this.myDeal.warehouse_id = this.deal.warehouse_id
        ? this.deal.warehouse_id
        : 1;

      if (this.deal.site_id)
        this.mySites[0] = this.sites.find(s => s.id == this.deal.site_id);
      else this.mySites = [];
      this.myDeal.trigger = this.deal.buy && this.deal.buy.value;
      this.myDeal.type = this.deal.type;
      if (this.myDeal.type == "cashback") {
        this.trigger_disabled = true;
        this.offer_suffix = "%";
      }
      this.myDeal.prod_id = this.deal.product ? this.deal.product.id : null;
      this.myDeal.size_id = this.deal.size ? this.deal.size.id : null;
      this.myDeal.starts_at = this.deal.starts_at
        ? moment
            .parseZone(this.deal.starts_at.datetime)
            .format("YYYY-MM-DD HH:mm:ss")
        : null;

      this.myDeal.ends_at = this.deal.ends_at
        ? moment
            .parseZone(this.deal.ends_at.datetime)
            .format("YYYY-MM-DD HH:mm:ss")
        : null;
      this.myDeal.offer = this.deal.get ? parseInt(`${this.deal.get}`) : null;
      this.myDeal.combo = this.deal && this.deal.combo;

      if (this.deal.details) {
        this.offered_products.length = 0;
        for (let i = 0; i < this.deal.details.length; i++) {
          let detail = this.deal.details[i];
          this.offered_products.push({
            prod_ref_id: detail.prod_ref_id,
            threshold: detail.threshold,
            products: [],
            search: null,
            label: `${detail.product.name} - ${detail.product.size} - ${detail.product.attribute}`
          });
        }
        this.offered_products.push({
          prod_ref_id: null,
          threshold: null,
          products: [],
          search: null,
          label: ""
        });
      }
    } else {
      // Setting warehouse_id to EUR
      this.myDeal.warehouse_id = this.warehouses[0].id;
      this.myDeal.starts_at = moment()
        .add(1, "M")
        .startOf("month")
        .toISOString();
      this.myDeal.ends_at = moment(this.myDeal.starts_at)
        .add(1, "M")
        .startOf("day")
        .toISOString();
    }
  },
  methods: {
    filterSites(search) {
      this.sitesitems = this.sites.filter(option => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    selectProduct(product) {
      this.myDeal.prod_id = product.product_id;
      this.myDeal.size_id = product.size_id;
    },
    selectSku(product) {
      let index = this.offered_products.findIndex(
        item => item.prod_ref_id === product.id
      );
      if (index != -1) {
        return;
      }
      this.offered_products.unshift({
        prod_ref_id: product.id,
        threshold: null,
        products: [],
        search: null
      });
    },
    updateSku() {
      for (let i = 0; i < this.offered_products.length; i++) {
        if (
          this.offered_products[i]["prod_ref_id"] &&
          i === this.offered_products.length - 1
        ) {
          this.newOfferedProducts();
        }
      }
    },
    newOfferedProducts() {
      this.offered_products.push({
        prod_ref_id: null,
        threshold: null,
        products: [],
        search: "",
        label: ""
      });
    },
    deleteOfferedProducts(index) {
      this.offered_products = this.offered_products.filter(
        (o, i) => i !== index
      );
    },
    saveDeal() {
      this.save_deal_loading = true;
      let finalDeal = {
        sites: this.mySites.reduce((arr, s) => [...arr, s.id], []),
        combo: this.myDeal.combo,
        trigger: this.myDeal.trigger,
        offer: this.myDeal.offer,
        warehouse_id: this.myDeal.warehouse_id,
        starts_at: this.myDeal.starts_at ? this.startsAtComputed : null,
        ends_at: this.myDeal.ends_at ? this.endsAtComputed : null,
        type: this.myDeal.type
      };
      if (this.myDeal.type !== "product") {
        finalDeal.brand_id = this.myDeal.brand_id;
      }
      if (this.myDeal.type !== "brand") {
        finalDeal.prod_id = this.myDeal.prod_id;
        finalDeal.size_id = this.myDeal.size_id;
      }
      let details = [];
      if (this.offered_products.length > 0) {
        for (let i = 0; i < this.offered_products.length; i++) {
          let offered = this.offered_products[i];
          if (offered.prod_ref_id)
            details.push({
              prod_ref_id: offered.prod_ref_id,
              threshold: offered.threshold
            });
        }
      }
      finalDeal.details = details;
      let self = this;
      self.save_loading = true;
      let deal_id = self.myDeal.id ? "/" + self.myDeal.id : "";
      self
        .$axios({
          method: self.method,
          url: "deals" + deal_id,
          data: finalDeal
        })
        .then(res => {
          self.$bus.$emit("save-deal", {
            index: self.index,
            deal: res.data
          });
          self.closeDrawer("main");
          //this.generateList(this.products, res.data, "product");
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.save_deal_loading = false));
    }
  }
};
</script>

